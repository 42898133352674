import {IS_PRODUCTION, IS_SERVER, IS_TEST, SENTRY_DSN} from 'common/constants';
import {createLogger, format, transports} from 'winston';
import BrowserConsole from 'winston-transport-browserconsole';
import Sentry from 'winston-transport-sentry-node';

const SPLAT = Symbol.for('splat');
const logger = createLogger({
  level: IS_TEST ? 'error' : IS_PRODUCTION ? 'error' : 'debug',
});

const setupLocalServerLogger = () => {
  logger.add(
    new transports.Console({
      format: format.combine(
        format(info => {
          info.level = info.level.toUpperCase();
          return info;
        })(),
        format.colorize(),
        format.timestamp({
          format: 'YYYY-MM-DD HH:mm:ss',
        }),
        // @ts-ignore
        format.printf(({timestamp, level, message, [SPLAT]: args = []}) => {
          const extra = args.length === 0 ? '' : JSON.stringify(args.length === 1 ? args[0] : args, null, 2);
          return `${timestamp} [${level}]: ${message} ${extra}`;
        })
      ),
    })
  );
};

const setupLocalBrowserLogger = () => {
  logger.add(
    new BrowserConsole({
      format: format.simple(),
    })
  );
};

const setupRemoteServerLogger = () => {
  logger.add(
    new Sentry({
      sentry: {dsn: SENTRY_DSN, serverName: 'nextjs'},
      format: format.combine(
        format(info => {
          info.level = info.level.toUpperCase();
          return info;
        })(),
        format.colorize(),
        format.timestamp({
          format: 'YYYY-MM-DD HH:mm:ss',
        }),
        // @ts-ignore
        format.printf(({timestamp, level, message, [SPLAT]: args = []}) => {
          const extra = args.length === 0 ? '' : JSON.stringify(args.length === 1 ? args[0] : args, null, 2);
          return `${timestamp} [${level}]: ${message} ${extra}`;
        })
      ),
    })
  );
};

if (IS_SERVER) {
  if (IS_PRODUCTION) {
    setupRemoteServerLogger();
  } else {
    setupLocalServerLogger();
  }
} else {
  setupLocalBrowserLogger();
}

export default logger;
