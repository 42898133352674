import {breakpoint} from 'common/theme';
import {FooterProps} from 'common/types';
import {motion} from 'framer-motion';
import Link from 'next/link';
import {FC} from 'react';
import styled from 'styled-components';
// @ts-ignore
import Grid from 'styled-components-grid';

const Wrapper = styled.section`
  padding: ${({theme}) => theme.spacing(1)}px;
  ${breakpoint.size('sm')`
    padding: ${({theme}) => theme.spacing(2)}px;
  `}
  width: 100%;
  background-color: ${({theme}) => theme.colors.lightblue};
`;

const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  margin-top: ${({theme}) => theme.spacing(1)}px;
  margin-bottom: ${({theme}) => theme.spacing(1)}px;
  font-size: ${({theme}) => theme.fontSize(0.75)}px;
  line-height: 1.35em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${breakpoint.size('sm')`
    font-size: ${({theme}) => theme.fontSize(0.9)}px;
  `}
`;

const Copyright = styled.div`
  font-size: ${({theme}) => theme.fontSize(0.75)}px;
  line-height: 1.35em;
  ${breakpoint.size('sm')`
    font-size: ${({theme}) => theme.fontSize(0.8)}px;
  `}
`;

const Title = styled.div`
  font-family: ${({theme}) => theme.fonts.teko};
  font-size: ${({theme}) => theme.fontSize(1.25)}px;
  line-height: 1.35em;
  margin-bottom: 0.75em;
  ${breakpoint.size('sm')`
    font-size: ${({theme}) => theme.fontSize(1.55)}px;
  `}
`;

const LinkItem = styled(motion.a)<{active?: boolean}>`
  text-transform: none;
  text-decoration: none;
  z-index: 1;
  color: ${({theme}) => theme.colors.black};
  font-family: ${({theme}) => theme.fonts.teko};
  margin-bottom: 0.5em;
  font-size: ${({theme}) => theme.fontSize(1.15)}px;
  ${breakpoint.size('sm')`
    font-size: ${({theme}) => theme.fontSize(1.35)}px;
  `}
  position: relative;
  cursor: pointer;
  &:hover {
    &:after {
      opacity: 0.8;
      right: 0;
    }
  }
  &:after {
    transition: opacity 400ms ease-in-out, right 200ms ease-in-out;
    content: '';
    display: inline-block;
    opacity: ${({active}) => (active ? `1` : `0`)};
    height: 40%;
    overflow: hidden;
    background: ${({theme, active}) => (active ? theme.colors.lightblue : theme.colors.blue)};
    position: absolute;
    right: ${({active}) => (active ? `0` : `100%`)};
    left: 0;
    bottom: 0;
    z-index: -1;
    margin-left: -5px;
    margin-right: -5px;
  }
  &:last-child {
    margin-bottom: 0px;
  }
`;

const Footer: FC<FooterProps> = props => {
  const year = new Date().getFullYear();
  return (
    <Wrapper>
      <Content>
        <Grid>
          <Grid.Unit size={{xs: 1, md: 1 / 2}}>
            <ContentWrapper>
              <Title>Links</Title>
              {props.items.map((item, index) => (
                <Link key={index} href={item.slug} passHref>
                  <LinkItem whileTap={{scale: 0.95}} whileHover={{scale: 1.05}}>
                    {item.title}
                  </LinkItem>
                </Link>
              ))}
            </ContentWrapper>
          </Grid.Unit>
          <Grid.Unit size={{xs: 1, md: 1 / 2}}>
            <ContentWrapper>
              <Title>Kontakt</Title>
              Christine Fink
              <br />
              Gass 29
              <br />
              6866 Andelsbuch
              <br />
              Tel. +43(0)670/6062914
            </ContentWrapper>
          </Grid.Unit>
          <Grid.Unit size={1}>
            <ContentWrapper>
              <Copyright>© {year} Löchle. Ferienhütte im Bregenzerwald.</Copyright>
            </ContentWrapper>
          </Grid.Unit>
        </Grid>
      </Content>
    </Wrapper>
  );
};

export default Footer;
