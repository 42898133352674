export const API_ROUTE_DAYS = '/api/days';
export const API_ROUTE_PRICES = '/api/prices';
export const API_ROUTE_CONTACTS = '/api/contacts';

export const IS_PRODUCTION = process.env.NODE_ENV === 'production';
export const IS_TEST = process.env.NODE_ENV === 'test';
export const IS_SERVER = typeof window === 'undefined';
export const IS_BROWSER = typeof window !== 'undefined';

export const TEST_HOST = 'http://localhost:3000';
export const DEVELOPMENT_HOST = 'http://localhost:3000';
export const PRODUCTION_HOST = 'https://ferienhuetteloechle.at';

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_FL_GA_TRACKING_ID || '';
export const DATABASE_CONNECTION = process.env.FL_DATABASE_CONNECTION;

export const ACKEE_SERVER = process.env.NEXT_PUBLIC_ACKEE_SERVER || '';
export const ACKEE_DOMAIN_ID = process.env.NEXT_PUBLIC_ACKEE_DOMAIN_ID || '';

export const MAIL_HOST = process.env.FL_MAIL_HOST;
export const MAIL_PORT = Number(process.env.FL_MAIL_PORT) || 0;
export const MAIL_USER = process.env.FL_MAIL_USER;
export const MAIL_PASSWORD = process.env.FL_MAIL_PASSWORD;
export const MAIL_COPY = process.env.FL_MAIL_COPY;

export const TRAUM_API_URL = process.env.FL_TRAUM_API_URL;
export const TRAUM_EMAIL = process.env.FL_TRAUM_EMAIL;
export const TRAUM_PASSWORD = process.env.FL_TRAUM_PASSWORD;
export const TRAUM_LISTING_ID = process.env.FL_TRAUM_LISTING_ID;

export const CALENDAR_DAYS = 365;
export const BASE_PRICE_PEOPLE = 4;

export const SENTRY_DSN = process.env.FL_SENTRY_DSN;

export const SANITY_AUTHORIZATION = process.env.FL_SANITY_AUTHORIZATION;

export const COOKIE_USER_ACCEPTED = 'acceptCookie';
export const COOKIE_USER_ACCEPTED_VALUE = 'true';
