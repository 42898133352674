import animateScrollTo from 'animated-scroll-to';
import {useRouter} from 'next/router';
import {useEffect} from 'react';
import {IS_BROWSER} from 'common/constants';

export const useRouteScroll = (): void => {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChangeComplete = () => {
      if (IS_BROWSER) {
        animateScrollTo(0, {cancelOnUserAction: false});
      }
    };
    router.events.on('routeChangeStart', handleRouteChangeComplete);
    return () => {
      router.events.off('routeChangeStart', handleRouteChangeComplete);
    };
  }, []);
};
