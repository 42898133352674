import styled from 'styled-components';
// @ts-ignore
import Grid from 'styled-components-grid';

export const Row = styled(Grid)`
  width: 100%;
  margin: ${({theme}) => theme.spacing(-0.5)}px;
  width: calc(100% + ${({theme}) => theme.spacing(1)}px);
`;

export const Col = styled(Grid.Unit)`
  padding: ${({theme}) => theme.spacing(0.5)}px;
`;
