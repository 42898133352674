/* eslint-disable no-shadow */
import {createGlobalStyle, DefaultTheme} from 'styled-components';
import {createBreakpoint, createMap} from 'styled-components-breakpoint';

const theme: DefaultTheme = {
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  colors: {
    black: '#000000',
    white: '#FFFFFF',
    lightblue: '#E7F3FF',
    blue: '#4c7d8d',
    gray: '#C6C6C6',
    lightgray: '#ededed',
    red: 'red',
  },
  spacing: (factor: number) => factor * 20,
  fontSize: (factor: number) => factor * 20,
  fonts: {
    teko: 'Teko, sans-serif',
    merriweather: 'Merriweather, serif',
  },
};

const size = createBreakpoint(theme.breakpoints);
const map = createMap(theme.breakpoints);

export const breakpoint = {size, map};

export const GlobalStyle = createGlobalStyle`
  /* merriweather-300 - latin */
  @font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/merriweather-v30-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('/fonts/merriweather-v30-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('/fonts/merriweather-v30-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
        url('/fonts/merriweather-v30-latin-300.woff') format('woff'), /* Modern Browsers */
        url('/fonts/merriweather-v30-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
        url('/fonts/merriweather-v30-latin-300.svg#Merriweather') format('svg'); /* Legacy iOS */
  }
  /* merriweather-regular - latin */
  @font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/merriweather-v30-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('/fonts/merriweather-v30-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('/fonts/merriweather-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
        url('/fonts/merriweather-v30-latin-regular.woff') format('woff'), /* Modern Browsers */
        url('/fonts/merriweather-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
        url('/fonts/merriweather-v30-latin-regular.svg#Merriweather') format('svg'); /* Legacy iOS */
  }
  /* merriweather-700 - latin */
  @font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/merriweather-v30-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('/fonts/merriweather-v30-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('/fonts/merriweather-v30-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
        url('/fonts/merriweather-v30-latin-700.woff') format('woff'), /* Modern Browsers */
        url('/fonts/merriweather-v30-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
        url('/fonts/merriweather-v30-latin-700.svg#Merriweather') format('svg'); /* Legacy iOS */
  }
  /* teko-300 - latin */
  @font-face {
    font-family: 'Teko';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/teko-v15-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('/fonts/teko-v15-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('/fonts/teko-v15-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
        url('/fonts/teko-v15-latin-300.woff') format('woff'), /* Modern Browsers */
        url('/fonts/teko-v15-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
        url('/fonts/teko-v15-latin-300.svg#Teko') format('svg'); /* Legacy iOS */
  }
  /* teko-regular - latin */
  @font-face {
    font-family: 'Teko';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/teko-v15-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('/fonts/teko-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('/fonts/teko-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
        url('/fonts/teko-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
        url('/fonts/teko-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
        url('/fonts/teko-v15-latin-regular.svg#Teko') format('svg'); /* Legacy iOS */
  }
  /* teko-700 - latin */
  @font-face {
    font-family: 'Teko';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/teko-v15-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('/fonts/teko-v15-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('/fonts/teko-v15-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
        url('/fonts/teko-v15-latin-700.woff') format('woff'), /* Modern Browsers */
        url('/fonts/teko-v15-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
        url('/fonts/teko-v15-latin-700.svg#Teko') format('svg'); /* Legacy iOS */
  }
  body.disable-scroll {
    overflow-y: hidden;
  }
  html, body, div#__next {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  ::selection {
    background: ${({theme}) => theme.colors.blue};
    color: ${({theme}) => theme.colors.white};
  }
  div#__next {
    display: flex;
    flex-direction: column;
  }
  html,
  body {
    padding: 0;
    margin: 0;
    font-family: ${({theme}) => theme.fonts.merriweather};
    font-size: ${({theme}) => theme.fontSize(1)}px;
    background-color: ${({theme}) => theme.colors.white};
  }
  * {
    word-break: break-word;
  }
  textarea, input {
    font-family: ${({theme}) => theme.fonts.merriweather};
  }
  .react-images__dialog {
    width: 100vw !important;
  }
  img.react-images__view-image {
    border-radius: 5px;
    max-height: 95vh;
  }
  div.react-images__view.react-images__view--isModal {
    margin: ${({theme}) => theme.spacing(0.5)}px;
  }
  button, input, textarea {
    outline: none;
    &:focus {
      box-shadow: 0 0 0 3px ${({theme}) => theme.colors.blue}57;
    }
  }
  .calendar {
    z-index: 1;
    width: 100%;
    max-width: 100%;
    border-radius: 5px;
    border: 1px solid ${({theme}) => theme.colors.blue};
    transition: border 250ms ease-in-out;
    box-shadow: none;
    .Calendar__day:focus {
      outline: none;
    }
    .Calendar__monthYear {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    .Calendar__monthArrowWrapper,
    .Calendar__monthText,
    .Calendar__yearText {
      box-shadow: none;
    }
    .Calendar__monthSelector,
    .Calendar__yearSelector {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-content: center;
      margin: 0 0.75em 0.75em 0.75em;
      padding: 0;
      width: calc(100% - 1.5em);
    }
    .Calendar__monthSelectorItem,
    .Calendar__yearSelectorItem {
      min-width: 25%;
      width: unset;
      margin-top: 0px;
      margin: 0.2em;
      & button {
        padding: 0.25em 0.5em;
        font-size: ${({theme}) => theme.fontSize(0.75)}px;
      }
    }
    .Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
      border-radius: 0;
      border-top-left-radius: 100em;
      border-bottom-left-radius: 100em;
      border-top-right-radius: 100em;
      border-bottom-right-radius: 100em;
    }
  }
  h1, h2 {
    font-family: ${({theme}) => theme.fonts.teko};
  }
  h1 {
    font-size: ${({theme}) => theme.fontSize(1.75)}px;
    ${breakpoint.size('sm')`
      font-size: ${({theme}) => theme.fontSize(2.5)}px;
    `}
    ${breakpoint.size('md')`
      font-size: ${({theme}) => theme.fontSize(3.5)}px;
    `}
  }
  h2 {
    font-size: ${({theme}) => theme.fontSize(1.5)}px;
    ${breakpoint.size('sm')`
      font-size: ${({theme}) => theme.fontSize(2)}px;
    `}
    ${breakpoint.size('md')`
      font-size: ${({theme}) => theme.fontSize(3)}px;
    `}
  }
  * {
    box-sizing: border-box;
  }
`;

export default theme;
