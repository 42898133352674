import {useRouter} from 'next/router';
import {useEffect} from 'react';
import * as ackeeTracker from 'ackee-tracker';
import {ACKEE_DOMAIN_ID, ACKEE_SERVER, IS_BROWSER} from 'common/constants';

export const useAckeeAnalytics = () => {
  const router = useRouter();

  const pageview = () => {
    if (IS_BROWSER) {
      ackeeTracker
        .create(
          {
            server: ACKEE_SERVER,
            domainId: ACKEE_DOMAIN_ID,
          },
          {
            detailed: true,
            ignoreLocalhost: true,
          }
        )
        .record();
    }
  };

  useEffect(() => {
    if (IS_BROWSER) {
      pageview();
      router.events.on('routeChangeComplete', pageview);
    }
  }, []);
};
