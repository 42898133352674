import styled from 'styled-components';

export const PageWrapper = styled.div``;

const Page = styled.div`
  width: 100%;
  height: 100%;
`;

export default Page;
