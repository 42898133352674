import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import theme, {GlobalStyle} from 'common/theme';
import {FooterItem, MenuItem} from 'common/types';
import {useAckeeAnalytics} from 'common/useAckeeAnalytics';
import {useGoogleAnalytics} from 'common/useGoogleAnalytics';
import {usePageHeight} from 'common/usePageHeight';
import {useRouteScroll} from 'common/useRouteScroll';
import Footer from 'components/footer';
import Menu from 'components/menu';
import {PageWrapper} from 'components/page';
import {AppProps} from 'next/app';
import {useRouter} from 'next/router';
import {ThemeProvider} from 'styled-components';
import dynamic from 'next/dynamic';
const Loading = dynamic(() => import('components/loading'));

const menuItems: MenuItem[] = [
  {title: 'Startseite', primary: true, slug: '/'},
  {title: 'Das Haus', primary: true, slug: '/das-haus'},
  {title: 'Entdecken', primary: true, slug: '/entdecken'},
  {title: 'Anfrage', primary: true, slug: '/anfrage'},
  {title: 'Belegung', slug: '/belegung'},
  {title: 'Galerie', slug: '/galerie'},
  {title: 'Fragen', slug: '/fragen'},
  {title: 'Datenschutz', slug: '/datenschutz'},
  {title: 'Impressum', slug: '/impressum'},
];

const footerItems: FooterItem[] = [
  {title: 'Das Haus', slug: '/das-haus'},
  {title: 'Anfrage', slug: '/anfrage'},
  {title: 'Impressum', slug: '/impressum'},
  {title: 'Datenschutz', slug: '/datenschutz'},
];

const App = ({Component, pageProps}: AppProps) => {
  const router = useRouter();
  useRouteScroll();
  usePageHeight();
  useGoogleAnalytics();
  useAckeeAnalytics();
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Menu items={menuItems} />
      <PageWrapper key={router.asPath}>
        <Component {...pageProps} />
      </PageWrapper>
      {router.isFallback && <Loading />}
      <Footer items={footerItems} />
    </ThemeProvider>
  );
};

export default App;
