import {IS_BROWSER} from 'common/constants';
import {breakpoint} from 'common/theme';
import {MenuProps} from 'common/types';
import {Col, Row} from 'components/grid';
import {motion} from 'framer-motion';
import Link from 'next/link';
import {useRouter} from 'next/router';
import {FC, useEffect, useState} from 'react';
import styled from 'styled-components';

export const MENU_BAR_HEIGHT = 60;

const MenuBarWrapper = styled.div`
  width: 100%;
  padding: 0 ${({theme}) => theme.spacing(1)}px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  background: linear-gradient(
    90deg,
    ${({theme}) => theme.colors.white}cc 70%,
    ${({theme}) => theme.colors.lightblue}cc 30%
  );
  backdrop-filter: saturate(180%) blur(10px);
`;

const MenuBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${MENU_BAR_HEIGHT}px;
  min-height: ${MENU_BAR_HEIGHT}px;
  max-width: 1200px;
  margin: 0 auto;
`;

const MenuBarTitleWrapper = styled.div`
  flex: 1;
  display: flex;
`;

const MenuBarTitle = styled.div`
  display: flex;
  font-weight: bold;
  cursor: pointer;
  font-family: ${({theme}) => theme.fonts.teko};
  font-size: ${({theme}) => theme.fontSize(1)}px;
`;

const HamburgerWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const MenuContentWrapper = styled(motion.div)`
  position: fixed;
  top: 0px;
  overflow: hidden auto;
  right: 0px;
  left: 0px;
  bottom: 0;
  z-index: 10;
  will-change: bottom;
  padding: 0 ${({theme}) => theme.spacing(1)}px;
  background: linear-gradient(
    90deg,
    ${({theme}) => theme.colors.white} 70%,
    ${({theme}) => theme.colors.lightblue} 30%
  );
`;

const MenuContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: center;
  padding-top: ${({theme}) => theme.spacing(1) + MENU_BAR_HEIGHT}px;
  padding-bottom: ${({theme}) => theme.spacing(1) + MENU_BAR_HEIGHT}px;
  ${breakpoint.size('sm')`
    padding-left: ${({theme}) => theme.spacing(2.5)}px;
    padding-right: ${({theme}) => theme.spacing(2.5)}px;
  `}
  ${breakpoint.size('md')`
    padding-top: ${({theme}) => theme.spacing(1.5) + MENU_BAR_HEIGHT}px;
    padding-bottom: ${({theme}) => theme.spacing(1.5) + MENU_BAR_HEIGHT}px;
    padding-left: ${({theme}) => theme.spacing(4)}px;
    padding-right: ${({theme}) => theme.spacing(4)}px;
  `}
  ${breakpoint.size('lg')`
    padding-left: ${({theme}) => theme.spacing(5)}px;
    padding-right: ${({theme}) => theme.spacing(5)}px;
  `}
`;

const LargeMenuItemWrapper = styled(motion.div)`
  margin-bottom: ${({theme}) => theme.spacing(1)}px;
`;

const RegularMenuItemWrapper = styled(motion.div)`
  margin-bottom: ${({theme}) => theme.spacing(0.75)}px;
`;

const BaseMenuItem = styled.div<{active?: boolean}>`
  font-family: ${({theme}) => theme.fonts.teko};
  display: inline-block;
  position: relative;
  cursor: pointer;
  &:hover {
    &:after {
      opacity: 0.7;
      right: 0;
    }
  }
  &:after {
    transition: opacity 400ms ease-in-out, right 200ms ease-in-out;
    content: '';
    display: inline-block;
    opacity: ${({active}) => (active ? `1` : `0`)};
    height: 40%;
    overflow: hidden;
    background: ${({theme, active}) => (active ? theme.colors.lightblue : theme.colors.blue)};
    position: absolute;
    right: ${({active}) => (active ? `0` : `100%`)};
    left: 0;
    bottom: 0;
    z-index: -1;
    margin-left: -5px;
    margin-right: -5px;
  }
`;

const MenuToggleItem = styled.div<{active?: boolean}>`
  font-family: ${({theme}) => theme.fonts.merriweather};
  font-size: ${({theme}) => theme.fontSize(0.85)}px;
  display: inline-block;
  font-weight: 700;
  position: relative;
  cursor: pointer;
  &:hover {
    &:after {
      opacity: 0.7;
      right: 0;
    }
  }
  &:after {
    transition: opacity 400ms ease-in-out, right 200ms ease-in-out;
    content: '';
    display: inline-block;
    opacity: ${({active}) => (active ? `0.8` : `0`)};
    height: 40%;
    overflow: hidden;
    background: ${({theme}) => theme.colors.blue};
    position: absolute;
    right: ${({active}) => (active ? `0` : `100%`)};
    left: 0;
    bottom: 0;
    z-index: -1;
    margin-left: -5px;
    margin-right: -5px;
  }
`;

const LargeBaseMenuItem = styled(BaseMenuItem)`
  font-weight: 600;
  font-size: ${({theme}) => theme.fontSize(1.75)}px;
  line-height: ${({theme}) => theme.fontSize(1.75)}px;
  ${breakpoint.size('md')`
    font-size: ${({theme}) => theme.fontSize(3.5)}px;
    line-height: ${({theme}) => theme.fontSize(3.5)}px;
  `}
`;

const RegularBaseMenuItem = styled(BaseMenuItem)`
  font-weight: 500;
  font-size: ${({theme}) => theme.fontSize(1.25)}px;
  line-height: ${({theme}) => theme.fontSize(1.25)}px;
  ${breakpoint.size('md')`
    font-size: ${({theme}) => theme.fontSize(1.75)}px;
    line-height: ${({theme}) => theme.fontSize(1.75)}px;
  `}
`;

const LinkChild = styled.a`
  color: ${({theme}) => theme.colors.black};
`;

const ItemWrapper = styled(motion.div)``;

const containerVariants = {
  open: {y: '0px'},
  close: {y: '-100%'},
};

const itemWrapperVariants = {
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
      delayChildren: 0.25,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const itemVariants = {
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      y: {stiffness: 1000, velocity: -100},
    },
  },
  hidden: {
    y: -20,
    opacity: 0,
    transition: {
      y: {stiffness: 1000},
    },
  },
  hover: {
    scale: 1.05,
  },
  tap: {
    scale: 0.95,
  },
};

const Menu: FC<MenuProps> = ({items}) => {
  const router = useRouter();
  const [open, setOpen] = useState<boolean>(false);

  const primaryItems = items.filter(item => item.primary);
  const secondaryItems = items.filter(item => !item.primary);

  const toggleMenu = () => setOpen(open => !open);
  const closeMenu = () => setOpen(false);

  const isActive = (slug: string): boolean => {
    return router?.asPath === slug;
  };

  useEffect(() => {
    if (IS_BROWSER) {
      window?.document?.body?.classList?.toggle('disable-scroll', open);
    }
  }, [open]);

  return (
    <>
      <MenuBarWrapper>
        <MenuBar>
          <MenuBarTitleWrapper>
            <Link href='/'>
              <MenuBarTitle onClick={closeMenu}>Löchle.</MenuBarTitle>
            </Link>
          </MenuBarTitleWrapper>
          <HamburgerWrapper>
            <MenuToggleItem onClick={toggleMenu} active={open}>
              Menu
            </MenuToggleItem>
          </HamburgerWrapper>
        </MenuBar>
      </MenuBarWrapper>
      <MenuContentWrapper
        variants={containerVariants}
        initial='close'
        animate={open ? 'open' : 'close'}
        transition={{duration: 0.65}}>
        <MenuContent>
          <Row valign='bottom'>
            <Col size={{xs: 1, sm: 3 / 5, lg: 3 / 6}}>
              <ItemWrapper variants={itemWrapperVariants} animate={open ? 'visible' : 'hidden'} initial={{opacity: 0}}>
                {primaryItems.map((item, index) => (
                  <LargeMenuItemWrapper
                    key={index}
                    onClick={closeMenu}
                    variants={itemVariants}
                    whileHover='hover'
                    whileTap='tap'>
                    <Link href={item.slug} passHref>
                      <LinkChild>
                        <LargeBaseMenuItem active={isActive(item.slug)}>{item.title}</LargeBaseMenuItem>
                      </LinkChild>
                    </Link>
                  </LargeMenuItemWrapper>
                ))}
              </ItemWrapper>
            </Col>
            <Col size={{xs: 1, sm: 2 / 5, lg: 2 / 6}}>
              <ItemWrapper variants={itemWrapperVariants} animate={open ? 'visible' : 'hidden'} initial={{opacity: 0}}>
                {secondaryItems.map((item, index) => (
                  <RegularMenuItemWrapper
                    key={index}
                    onClick={closeMenu}
                    variants={itemVariants}
                    whileHover='hover'
                    whileTap='tap'>
                    <Link href={item.slug} passHref>
                      <LinkChild>
                        <RegularBaseMenuItem active={isActive(item.slug)}>{item.title}</RegularBaseMenuItem>
                      </LinkChild>
                    </Link>
                  </RegularMenuItemWrapper>
                ))}
              </ItemWrapper>
            </Col>
          </Row>
        </MenuContent>
      </MenuContentWrapper>
    </>
  );
};

export default Menu;
