import {useEffect} from 'react';
import {IS_BROWSER} from 'common/constants';

export const usePageHeight = (): void => {
  useEffect(() => {
    if (IS_BROWSER) {
      const setHeight = () => {
        const vh = window.innerHeight;
        window.document.documentElement.style.setProperty('--vh', `${vh}px`);
      };
      setHeight();
      window.addEventListener('resize', setHeight);
      return () => {
        window.removeEventListener('resize', setHeight);
      };
    }
    return () => {};
  }, []);
};
