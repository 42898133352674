import {useRouter} from 'next/router';
import {useEffect} from 'react';
import ReactGA from 'react-ga';
import {GA_TRACKING_ID, IS_BROWSER, IS_PRODUCTION} from 'common/constants';
import logger from 'common/logger';

export const useGoogleAnalytics = () => {
  const router = useRouter();

  const pageview = (asPath: string) => {
    if (IS_PRODUCTION && IS_BROWSER) {
      const page = asPath.replace('/index', '/');
      logger.debug('pageview', page);
      ReactGA.set({page});
      ReactGA.pageview(page);
    }
  };

  const handleRouteChangeComplete = (asPath: string) => {
    pageview(asPath);
  };

  useEffect(() => {
    if (IS_PRODUCTION && IS_BROWSER) {
      ReactGA.initialize(GA_TRACKING_ID, {debug: false});
      pageview(window.location.pathname);
      router.events.on('routeChangeComplete', handleRouteChangeComplete);
    }
  }, []);
};
